import React, { useEffect } from 'react';
import Router, { useRouter } from 'next/router';
import Head from 'next/head';

import { Col, Row } from 'antd'; 

import 'react-chat-elements/dist/main.css';
import 'antd-mobile/dist/antd-mobile.min.css';
import '../styles/globals.css'

import NextNprogress from 'nextjs-progressbar';
import { Offline } from "react-detect-offline";
import OfflineMessage from '~/components/offlinemessage';

import { UserInfoProvider } from '~/components/UserInfoContext';
import { DisclaimerInfoProvider } from '~/components/DisclaimerInfoContext'

function MyApp({ Component, pageProps }) {

	const router = useRouter()

	useEffect(() => {
		sessionStorage.setItem("current-url", router.asPath);
	});

	useEffect(() => {
		if (window)
		{
			window.addEventListener('storage', (e) => {
				if (e.key == "LOGOUT_EVENT" && sessionStorage.getItem("userInfo"))
				{
					Router.push("/sessionexpired");
				}
			});
		}
	}, []);	

	useEffect(() => {
		console.log('%cStop!', 'color: #ff3b30; font-size: 30px; font-weight: bold;');
		console.log('%cThis is a browser feature intended for developers. If someone told you to copy and paste something here to enable a Fastrbid feature or \'hack\' someone\'s account, it is a scam and will give them access to your Fastrbid account.', 'color: #007aff; font-size: 14px; font-weight: bold;');
	}, []);

  	return (
		<>
			<Head lang="en">
				<title>Fastrbid - {Component.title}</title>
				<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=5" /> 
				<meta name="theme-color" content="#0076ff"/>		  
				<meta name="robots" content="index,follow" />
				<meta name="googlebot" content="index,follow" />
				<meta name="google" content="notranslate" />
				<meta name="google" content="nositelinkssearchbox" />
				<meta name="googlebot" content="notranslate" />
				<meta name="google" content="nopagereadaloud" />
				<meta httpEquiv="Cache-Control" content="no-cache, no-store, must-revalidate" />
				<meta httpEquiv="Pragma" content="no-cache" />
				<meta httpEquiv="Expires" content="0" />
				<meta name="google-site-verification" content="u7WNFWkyyNz2CU50ExhzaFUgjPxrnE6Q-BvvbF6ivDQ" />
				<meta charSet="utf-8"/>
				<meta name="description" content="B2B Online Auction Networking Platform, Win Win for Buyers and Sellers. Zero Commission - Zero Buyer Fees - Zero Risk." />
				<meta name="keywords" content="b2b auction, online auction, fastrbid, auction network, fatrbid buyers and sellers" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link rel="manifest" href="/site.webmanifest"></link>
			</Head>
			<DisclaimerInfoProvider>
			<UserInfoProvider>
				<NextNprogress
					color="#ff3b30"
					options={{ showSpinner: false }}
					startPosition={0.3}
					stopDelayMs={200}
					height={3}
					showOnShallow={false} />
				<Offline polling={{
					"enabled": true,
					"url": "https://ipv4.icanhazip.com"
				}}>
					<Row align="middle" justify="center">
						<Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} align="center">	
							<OfflineMessage />
						</Col>
					</Row>
				</Offline>
				<Component {...pageProps} />
			</UserInfoProvider>
			</DisclaimerInfoProvider>
		</>  
	) 
    
}

export default MyApp
