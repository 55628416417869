import React from 'react'

const initialState = {
	userInfo: {
		'isAuthorized': false
	}
};

const UserInfoContext = React.createContext(initialState);

class UserInfoProvider extends React.Component {

	constructor() {
		super();
		this.setUserInfo = this.setUserInfo.bind(this);
		this.resetUserInfo = this.resetUserInfo.bind(this);
		this.updateUserInfo = this.updateUserInfo.bind(this);
		this.state = {
			initialState
		};
	}

	setUserInfo(userInfo) {
		let newUserInfo = { ...initialState.userInfo, ...userInfo };
		this.setState({
			userInfo: newUserInfo
		});
		sessionStorage.setItem('userInfo', JSON.stringify(newUserInfo));
		localStorage.setItem('userInfo', JSON.stringify(newUserInfo));
	}

	updateUserInfo(key, value) {
		let newUserInfo = {
			[key]: value
		};
		let userInfo = { ...JSON.parse(sessionStorage.getItem('userInfo')), ...newUserInfo };
		this.setState({
			'userInfo': userInfo
		})
		sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
		localStorage.setItem('userInfo', JSON.stringify(userInfo));
	}

	resetUserInfo() {
		this.setState(initialState);
		sessionStorage.removeItem('userInfo');
		localStorage.removeItem('userInfo');
	}

	componentDidMount() {
		if (localStorage.getItem('userInfo') != null) {
			sessionStorage.setItem('userInfo', localStorage.getItem('userInfo'));
		}
		this.setState({
			'userInfo': JSON.parse(localStorage.getItem('userInfo')) || JSON.parse(sessionStorage.getItem('userInfo')) || initialState
		});
	}

	render() {
		return (
			<UserInfoContext.Provider
				value={{
					setUserInfo: this.setUserInfo,
					updateUserInfo: this.updateUserInfo,
					resetUserInfo: this.resetUserInfo,
					userInfo: this.state.userInfo
				}}
			>
				{this.props.children}
			</UserInfoContext.Provider>
		)
	}
}

export { UserInfoContext, UserInfoProvider }