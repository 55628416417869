import React from 'react';

const DisclaimerInfoContext = React.createContext({
  disclaimerAccepted: true
});

class DisclaimerInfoProvider extends React.Component {

  constructor() {
    super();
    this.acceptDisclaimer = this.acceptDisclaimer.bind(this);
    this.state = {
      disclaimerAccepted: true
    };
  }

  acceptDisclaimer() {
    localStorage.setItem('DISCLAIMER_ACCEPTED', true);
    this.setState({
      'disclaimerAccepted': true
    });
  }

  componentDidMount() {
    if (localStorage.getItem('DISCLAIMER_ACCEPTED')) {
      this.setState({
        'disclaimerAccepted': true
      });
    }
    else {
      this.setState({
        'disclaimerAccepted': false
      });
    }
  }

  render() {
    return (
      <DisclaimerInfoContext.Provider
        value={{
          acceptDisclaimer: this.acceptDisclaimer,
          disclaimerAccepted: this.state.disclaimerAccepted
        }}
      >
        {this.props.children}
      </DisclaimerInfoContext.Provider>
    )
  }

}

export { DisclaimerInfoContext, DisclaimerInfoProvider }