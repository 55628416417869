import React from 'react';

import { Alert, Typography } from 'antd';
import Marquee from 'react-fast-marquee';

const { Text } = Typography;

export default function OfflineMessage() {

	return (
		<Alert banner message={<Marquee pauseOnHover gradient={false}>
			<Text strong>Oops, seems like you&apos;re offline! Try once again later when your internet connection improves and we also will try to connect you back.</Text>
		</Marquee>} type="error" showIcon />
	);

}